.carousel-productivo {
    right: 15% !important;
    bottom: 10% !important;
    left: 40% !important;
}

.carousel-particular {
    right: 40% !important;
    bottom: 10% !important;
    left: 15% !important;
}

.carousel-libre {
    right: 15% !important;
    bottom: 10% !important;
    left: 40% !important;
}

.carousel-caption h3 {
    text-transform: uppercase;
    color: #093a89;
}

.carousel-caption p {

    color: #1f5bc9;
}

.img-banner-title {
    width: 30%;
    margin-top: -20px;
}

.no-arrows>.carousel-control-next,
.no-arrows>.carousel-control-prev,
.no-arrows>.carousel-indicators {
    display: none;
}
.carousel-productivo-b {
    right: 5% !important;
    bottom: 0% !important;
    left: 70% !important;
    padding-bottom: 0 !important;

}

.carousel-productivo-b h3 {
    text-align: right;
}



.img-banner {
    width: 60%;
    float: right;
}

.container p,
.container li,
.container h3,
.container h5,
.container label {
    color: #093a89;
}

.nav-link,
.dropdown-menu a {
    color: #012967 !important;
}
.card{
   border: 1px solid rgb(1 41 103 / 18%);
   
}
.card-noborder {
    border: 0 !important;
}
.card-header{
   background-color: white;
}

.policy p {
    text-align: justify;
}
.separator-container-main{
    height: 210px;
}
.separator-container{
    height: 160px;
}
.blur-separator {
    background: rgb(1 41 103 / 62%);
    backdrop-filter: blur(5px);
    height: 150px;
    width: 100%;
    
}
.blur-main{
    height: 200px;
}
.separator {
    width: 100%;
   
   
    background-repeat: no-repeat;
    background-attachment: fixed;
    
    background-size: cover;

    position: absolute;
    right: 0;
    left: 0;

}
.separator-index{
    background-image: url('../images/foto\ Beneficio\ 6.jpg');
    background-position: 50% 30%;
}
.separator-productivo{
    background-image: url('../images/foto\ Beneficio\ 5.jpg');
    background-position: 50% 30%;
}
.separator-beneficio{
    background-image: url('../images/foto\ Beneficio\ 12.jpg');
    background-position: 50% 30%;
}
.separator-contacto{
    background-image: url('../images/foto\ Beneficio\ 10.jpg');
    background-position: 50% 30%;
}

.blur-separator h3,.blur-separator h2{
    color: #f7fafe;
    padding-top: 5% !important;
    font-size: 3rem;
}
.credit-diamond{
    
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
    
}
.credit-diamond a{
    text-decoration: none;
}
.credit-diamond:hover {
    
    -webkit-transform: scale(1.1);
	transform: scale(1.1);
    
}
.icon-requisito{
    max-width: 50%;
}
.map-area{
    overflow:hidden;

    padding-bottom:56.25%;

    position:relative;

    height:0;
}
.map-area iframe{

    left:0;

    top:0;

    height:100%;

    width:100%;

    position:absolute;

}
.btn-whatsapp{
    display:block;
    width:70px;
    height:70px;
    color:#fff;
    position: fixed;
    right:30px;
    bottom:10px;
    border-radius:50%;
    line-height:0px;
    text-align:center;
    z-index:999;
}
.btn-whatsapp img{
    width: 60px; height:60px;
}

@media (max-width : 425px) {
    .carousel-control-next {display:none;}
    .carousel-control-prev {display:none;}
    .carousel-indicators {display:none;}
    .carousel-caption{top: 10%;}
    .img-banner-title{display: none;}
    .carousel-libre{top: -15%;}
    .carousel-productivo-b .img-banner, .carousel-productivo-b .my-4{
        display: none;
    }
    .carousel-productivo-b{
        position: static;
        
    }
    .carousel-productivo-b h3{
       text-align: center;
       font-size: 25px;
        
    }
    .separator-container{
        height: 100px;
    }
    .blur-separator {
        height: 90px;
    }
}
